<template>
  <v-container fluid class="align-center">
    <v-row v-if="dataStudio || id === 'neurographic'" align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <transition appear name="fadeGroup">
          <v-card class="backgroundCard">
            <v-card-text
              style="color: white"
              class="text-center display-3 font-weight-bold"
              :class="{ 'headline font-weight-bold': $vuetify.breakpoint.xs }"
              >Благодарим за оплату</v-card-text
            >
            <v-card-text
              style="color: white; white-space: pre-line"
              :class="{ 'body-2': $vuetify.breakpoint.xs }"
              class="text-center headline font-weight-bold"
              ><span v-if="dataStudio"
                >Вы приобрели дополнительное сопровождение в студии
                {{ dataStudio.studioName }}</span
              ><span v-if="id === 'neurographic'"
                >Вы приобрели индивидуальный урок нейрографики</span
              >
            </v-card-text>
            <v-card-text
              style="color: white; white-space: pre-line"
              :class="{ 'body-2': $vuetify.breakpoint.xs }"
              class="text-center headline font-weight-bold"
              >Автор свяжется с вами в течение суток</v-card-text
            >
            <v-card-actions>
              <v-spacer />
              <v-btn :x-small="$vuetify.breakpoint.xs" dark class="mt-7" :to="path" text>
                продолжить</v-btn
              >
            </v-card-actions>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ThankYou',
  metaInfo: {
    title: 'Спасибо за покупку',
    titleTemplate: null
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      listStudios: state => state.studios.listStudios
    }),
    dataStudio() {
      return this.$store.getters.dataStudio(this.id)
    },
    path() {
      return this.dataStudio ? `/studio/${this.id}/1` : '/neurographicsList'
    }
  },
  created() {
    if (this.listStudios.length === 0) this.$store.dispatch('getListStudios', 'studios')
  }
}
</script>

<style scoped lang="stylus">
.backgroundCard{
  background: rgba(176, 190, 197, 0.3) !important
  padding: 10px
}
.align-center{
  height 100%
  background-image url("https://firebasestorage.googleapis.com/v0/b/first-project-6daea.appspot.com/o/admin%2Fnejro-ind-900.jpg?alt=media&token=db0853ed-616f-49fd-bb76-f72f30e4993a")
  background-size cover
}
.fadeGroup-enter-active, .fadeGroup-leave-active {
  transition: opacity 2s;
}
.fadeGroup-enter, .fadeGroup-leave-to {
  opacity: 0;
}
</style>
